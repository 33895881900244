import {checkTokenForExpire, getCookie, setAccessToken, setRefreshToken, deleteCookie} from './helpers';
import {cookieAttributes, pwpAccess, pwpRefresh} from './constants';
import { refreshUserTokens, getUserInfo } from "./api";
import {sendAmplitudeButtonEvent} from "./amplitude";

const signUpControls = document.getElementById('sign-up-controls');
const loggedControls = document.getElementById('logged-user-controls');
const logoutButton = document.getElementById('logout-button');

const cleanAuthCookies = () => {
    deleteCookie(pwpRefresh, cookieAttributes);
    deleteCookie(pwpAccess, cookieAttributes);
}

export const setUsersTokens = (data) => {
    const refreshToken = data[pwpRefresh];
    const accessToken = data[pwpAccess];
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
}

export const toggleLoggedState = () => {
    signUpControls.classList.add('d-none');
    loggedControls.classList.remove('d-none');

    try {
        localStorage.setItem('auth', JSON.stringify(true));
    } catch (_) {}
}

export const toggleLogoutState = () => {
    signUpControls.classList.remove('d-none');
    loggedControls.classList.add('d-none');

    try {
        localStorage.removeItem('auth');
    } catch (_) {}
}

export async function checkUsersCredentials() {
    const accessToken = getCookie(pwpAccess);
    const refreshToken = getCookie(pwpRefresh);

    try {
        if (!checkTokenForExpire(pwpAccess) && !checkTokenForExpire(pwpRefresh)) {
            toggleLogoutState();
            cleanAuthCookies();
            return;
        }
    } catch (_) {
        // invalid tokens
        cleanAuthCookies();
        toggleLogoutState();
        return;
    }

    // check user pwp-access token
    if (checkTokenForExpire(pwpAccess)) {
        try {
            await getUserInfo(accessToken);
            toggleLoggedState();
            return;
        } catch (e) {}
    }

    // refresh user tokens
    try {
        const response = await refreshUserTokens(refreshToken)
        setUsersTokens(response.data);
        toggleLoggedState();
    } catch (_) {
        toggleLogoutState();
        cleanAuthCookies();
    }
}

const handleLogout = () => {
    sendAmplitudeButtonEvent('Log out click');
    cleanAuthCookies();
    toggleLogoutState();
}

logoutButton.addEventListener('click', handleLogout);


// !!! Use this helper only after API calls !!!
export const isUserLogged = () => {
    try {
        const auth = localStorage.getItem('auth');

        if (auth) return true;
    } catch (_) {
        return false;
    }

    return false;
}
