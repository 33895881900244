import {API_URL} from "./constants";

const REFRESH_TOKEN_URL = API_URL + '/auth/buyer/refresh-tokens';
const USER_INFO_URL = API_URL + '/v2/client/buyer/info';
const PREMADES_URL = API_URL + '/v2/client/stream-lobby/public-search-stream-lobby-list';

const BASE_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

const GET_AUTH_HEADERS = (accessToken) => ({
    ...BASE_HEADERS,
    'authorization': `Bearer ${accessToken}`
})


export async function refreshUserTokens(refreshToken) {
    const response = await fetch(REFRESH_TOKEN_URL, {
        method: 'POST',
        headers: BASE_HEADERS,
        body: JSON.stringify({
            token: refreshToken
        })
    });

    if (response.status !== 200) {
        throw new Error('Invalid refresh token')
    }

    return await response.json();
}

export async function getUserInfo(accessToken) {
    const response = await fetch(USER_INFO_URL, {
        method: 'GET',
        headers: GET_AUTH_HEADERS(accessToken),
    });

    if (response.status !== 200) {
        throw new Error('Invalid access token')
    }

    return await response.json();
}

export async function getPremades(gameId, accessToken) {
    const response = await fetch(PREMADES_URL + `?game_id=${gameId}`, {
        method: 'GET',
        headers: accessToken ? GET_AUTH_HEADERS(accessToken) : BASE_HEADERS,
    });

    if (response.status !== 200) {
        throw new Error('Failed to fetch')
    }

    return await response.json();
}